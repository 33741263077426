import moment from 'moment';
import {toast} from 'react-toastify';

export const handleDefaultPayout = (
  values,
  getInvestedUsersByDateRange,
  dispatch,
  handleUpdateDividendId,
  onClose,
  form,
) => {
  const {
    assetCurrency,
    startDate,
    endDate,
    amountPerShare,
    company,
    instrumentId,
    principalPerShare,
    investmentCategory,
  } = values;
  let bondId, issuerId;
  let isBondType = investmentCategory === 'BOND';

  if (isBondType) {
    bondId = instrumentId?.split(';')[0];
    issuerId = instrumentId?.split(';')[1];
  }

  getInvestedUsersByDateRange({
    variables: {
      input: {
        assetCurrency,
        investmentCategory,
        instrumentId: bondId,
        ...(principalPerShare && {
          principalPerShare: Number(principalPerShare),
        }),
        companyId: isBondType ? issuerId : company.split(';')[1],
        ...(startDate && {startDate: moment(startDate).format()}),
        ...(endDate && {endDate: moment(endDate).format()}),
        amountPerShare: Number(amountPerShare),
      },
    },
    onError: error => toast.error(`Error something wrong -${error.message}`),
  })
    .then(({data: {getInvestedUsersByDateRange}}) => {
      if (getInvestedUsersByDateRange.__typename === 'Error') {
        toast.error(getInvestedUsersByDateRange.message);
      } else {
        dispatch(
          handleUpdateDividendId({
            dividendId: isBondType ? issuerId : company.split(';')[1],
            instrumentId: isBondType ? bondId : null,
          }),
        );
        toast.success(getInvestedUsersByDateRange?.message);
        form.resetFields();
        onClose();
      }
    })
    .catch(error => toast.error(error));
};

export const handleCSVPayoutUpload = (
  values,
  csvUrl,
  setCSVUrl,
  bulkUploadDividendPayout,
  dispatch,
  handleUpdateDividendId,
  onClose,
  form,
) => {
  const {
    assetCurrency,
    startDate,
    endDate,
    company,

    instrumentId,
    investmentCategory,
  } = values;
  if (!csvUrl) return toast.error('CSV File Required');
  let bondId, issuerId;
  let isBondType = investmentCategory === 'BOND';

  if (isBondType) {
    bondId = instrumentId?.split(';')[0];
    issuerId = instrumentId?.split(';')[1];
  }
  return bulkUploadDividendPayout({
    variables: {
      input: {
        assetCurrency,
        investmentCategory,
        instrumentId: bondId,
        url: csvUrl,
        companyId: isBondType ? issuerId : company.split(';')[1],
        ...(startDate && {
          startDate: moment(startDate).format(),
        }),
        ...(endDate && {endDate: moment(endDate).format()}),
      },
    },
  })
    .then(({data: {bulkUploadDividendPayout}}) => {
      if (bulkUploadDividendPayout.__typename === 'Error') {
        toast.error(bulkUploadDividendPayout.message);
      } else {
        handleUpdateDividendId({
          dividendId: isBondType ? issuerId : company.split(';')[1],
          instrumentId: isBondType ? bondId : null,
        }),
          toast.success(bulkUploadDividendPayout?.message);
        onClose();
        setCSVUrl(null);
        form.resetFields();
      }
    })
    .catch(error => toast.error(error));
};

export const handleSingleUser = (
  values,
  addDividendPayout,
  dispatch,
  onClose,
  handleUpdateDividendId,
  user,
  form,
) => {
  const {
    assetCurrency,
    startDate,
    endDate,
    company,
    investmentCategory,
    instrumentId,
    principalPerShare,
    amountPerShare,
    units,
  } = values;
  if (!user) return toast.error('Please select a user');
  let bondId, issuerId;
  let isBondType = investmentCategory === 'BOND';

  if (isBondType) {
    bondId = instrumentId?.split(';')[0];
    issuerId = instrumentId?.split(';')[1];
  }
  return addDividendPayout({
    variables: {
      input: {
        assetCurrency,
        investmentCategory,
        units: Number(units),
        instrumentId: bondId,
        ...(principalPerShare && {
          principalPerShare: Number(principalPerShare),
        }),
        userId: user.split(';')[2],
        amountPerShare: Number(amountPerShare),
        companyId: isBondType ? issuerId : company.split(';')[1],
        ...(startDate && {
          startDate: moment(startDate).format(),
        }),
        ...(endDate && {endDate: moment(endDate).format()}),
      },
    },
  })
    .then(({data: {addDividendPayout}}) => {
      if (addDividendPayout.__typename === 'Error') {
        toast.error(addDividendPayout.message);
      } else {
        dispatch(
          handleUpdateDividendId({
            dividendId: isBondType ? issuerId : company.split(';')[1],
            instrumentId: isBondType ? bondId : null,
          }),
        );
        toast.success(addDividendPayout?.message);
        onClose();
        form.resetFields();
      }
    })
    .catch(error => toast.error(error));
};

export const handleProcessCSVPayout = (
  values,
  generateAndExportInvestedUsers,
  onClose,
  form,
) => {
  const {
    assetCurrency,
    startDate,
    endDate,
    instrumentId,
    company,
    investmentCategory,
  } = values;

  let bondId, issuerId;
  let isBondType = investmentCategory === 'BOND';

  if (isBondType) {
    bondId = instrumentId?.split(';')[0];
    issuerId = instrumentId?.split(';')[1];
  }

  generateAndExportInvestedUsers({
    variables: {
      filter: {
        assetCurrency,
        investmentCategory,
        instrumentId: bondId,
        companyId: isBondType ? issuerId : company.split(';')[1],
        ...(startDate && {startDate: moment(startDate).format()}),
        ...(endDate && {endDate: moment(endDate).format()}),
        // amountPerShare: Number(amountPerShare),
      },
    },
    onError: error => toast.error(`Error something wrong -${error.message}`),
  })
    .then(({data: {generateAndExportInvestedUsers}}) => {
      if (generateAndExportInvestedUsers.__typename === 'Error') {
        toast.error(generateAndExportInvestedUsers.message);
      } else {
        toast.success(generateAndExportInvestedUsers?.message);
        form.resetFields();
        onClose();
      }
    })
    .catch(error => toast.error(error));
};
