import {useNavigate, useParams} from 'react-router-dom';
import backIcon from 'images/back.png';

import styles from './walletTransaction.module.scss';
import {
  DatePicker,
  Dropdown,
  Form,
  Input,
  Pagination,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import {useQuery} from '@apollo/client';
import {GET_ALL_WALLET_TRANSACTIONS_FOR_ADMIN} from 'graphql/queries/wallet';
import moment from 'moment';
import filterIcon from 'icons/filter.svg';
import {useState} from 'react';
import {SelectOutlined} from '@ant-design/icons';
import SubmitButton from 'components/Startup/components/SubmitButton';

import {currencySymbols} from 'utils/mock';
import DBModal from 'components/modal/modal';
import WireTransferDetails from './transferDetails/WireTransferDetails';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import {toast} from 'react-toastify';
import ManualTxModal from 'pages/adminUser/userDetails/tabs/UserWalletSettings/ManualTxModal';
import {Button} from '@dabafinance/react-components';
import EmailTriggerModal from './triggerModal/EmailTriggerModal';
import UpdateWalletTransaction from './UpdateWalletTransaction/UpdateWalletTransaction';
import {renderTransactionId} from 'utils/helper';

const WalletTransaction = () => {
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [bankDetails, setBankDetails] = useState({});
  const [openBankModal, setOpenBankModal] = useState(false);
  const [endDate, setEndDate] = useState('');
  const [activeStatus, setActiveStatus] = useState('');
  const [actionType, setActionType] = useState('');
  const {currency, provider, type} = useParams();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [displayType, setDisplayType] = useState(type || 'ALL');
  const [searchValue, setSearchValue] = useState('');
  const [openTxModal, setOpenTxModal] = useState({isOpen: false});
  const [openEmailModal, setOpenEmailModal] = useState({isOpen: false});
  const [updateModal, setUpdateModal] = useState({
    isOpen: false,
    content: null,
  });
  const [copied, setCopied] = useState(false);

  const handleCopy = referenceId => {
    navigator.clipboard.writeText(referenceId).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 400);
    });
  };

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Wallet),
  );

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
    handleFilter('', page, limit);
  };

  let filterOption = {};
  if (provider) {
    filterOption.provider = provider;
  }

  if (type) {
    filterOption.type = type;
  }

  const {data, loading, refetch} = useQuery(
    GET_ALL_WALLET_TRANSACTIONS_FOR_ADMIN,
    {
      variables: {
        input: {
          currency,
          filters: {
            ...filterOption,
          },
          pagination: {
            limit: 10,
            page: 1,
          },
        },
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
      onCompleted: data =>
        setTotalPages(data?.getAllTransactionsForAdmin?.totalDocs),
      onError: error => toast.error(error.message),
    },
  );

  const handleFilter = (values, page, limit) => {
    const filterObj = {};
    if (actionType !== 'ALL' && (actionType || type)) {
      filterObj.type = actionType || type;
    }
    setDisplayType(filterObj.type || 'ALL');
    if (startDate && startDate !== 'Invalid date') {
      filterObj.startDate = startDate;
    }
    if (activeStatus) {
      filterObj.status = activeStatus;
    }
    if (endDate && endDate !== 'Invalid date') {
      filterObj.endDate = endDate;
    }
    if (provider !== 'null') {
      filterObj.provider = provider;
    }
    if (values) setCurrentPage(1);
    if (page === 'ONLOAD') {
      filterObj.provider = provider;
      filterObj.type = type;
    }
    refetch({
      input: {
        currency,
        filters: {
          ...filterObj,
          ...(searchValue && {search: searchValue}),
        },
        pagination: {
          page: values ? 1 : page || currentPage,
          limit: limit,
        },
      },
    });
    setOpenFilterModal(false);
  };

  const handleOpenModal = (data, event) => {
    if (event.target.isConnected) {
      setOpenBankModal(true);
      setBankDetails(data);
    }
  };

  const column = [
    {
      title: 'Reference ID',
      dataIndex: 'referenceId',
      key: 'referenceId',
      render: (text, record) => (
        <Tooltip
          trigger="hover"
          title={copied ? 'Copied' : record?.referenceId}>
          <p
            onClick={e => {
              e.stopPropagation();
              handleCopy(record?.referenceId);
            }}
            className={styles.reference}>
            {renderTransactionId(record?.referenceId)}
          </p>
        </Tooltip>
      ),
    },
    {
      title: 'Provider ID',
      dataIndex: 'providerId',
      key: 'providerId',
      render: (text, record) => (
        <Tooltip
          trigger="hover"
          title={
            copied ? 'Copied' : record?.paymentGateway?.metadata?.transactionId
          }>
          <p
            onClick={e => {
              e.stopPropagation();
              handleCopy(record?.paymentGateway?.metadata?.transactionId);
            }}
            className={styles.reference}>
            {renderTransactionId(
              record?.paymentGateway?.metadata?.transactionId,
            )}
          </p>
        </Tooltip>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <p>{`${record?.user.firstName} ${record?.user?.lastName}`}</p>
      ),
    },

    {
      title: 'Direction',
      dataIndex: 'direction',
      key: 'direction',
      render: (text, record) => (
        <Tag color={record?.type === 'DEPOSIT' ? 'black' : 'default'}>
          {record?.type}
        </Tag>
      ),
    },
    {
      title: 'Provider',
      dataIndex: 'paymentProvider',
      key: 'paymentProvider',
      render: (text, record) => (
        <span>
          {record?.paymentProvider
            ?.toLowerCase()
            ?.replace(/\b\w/g, c => c.toUpperCase())}
        </span>
      ),
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (text, record) => (
        <div style={{width: 170}}>
          <span>
            {record?.paymentMetadata?.paymentMethod ? (
              <Tag
                color={
                  record?.paymentMetadata?.paymentMethod === 'MOBILE_MONEY'
                    ? 'cyan'
                    : 'geekblue'
                }>
                {record?.paymentMetadata?.paymentMethod
                  .replace(/_/g, ' ')
                  .toLowerCase()
                  .replace(/\b\w/g, c => c.toUpperCase())}
              </Tag>
            ) : (
              '-- -- --'
            )}
          </span>
          <SelectOutlined
            onClick={e => {
              e.stopPropagation();
              handleOpenModal(record?.paymentMetadata, e);
            }}
          />
        </div>
      ),
    },
    {
      title: 'Total Amount',
      dataIndex: 'amountNetOfFees',
      key: 'amountNetOfFees',
      render: (text, record) => (
        <span>
          {currencySymbols[record?.currency]?.symbol}
          {Number(
            record?.totalTransactionAmountIncludingFees || 0,
          )?.toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Amount (Net of Fees)',
      dataIndex: 'amountNetOfFees',
      key: 'amountNetOfFees',
      render: (text, record) => (
        <p>
          {Number(record?.transactionAmountNetOfFees || 0)?.toLocaleString()}
        </p>
      ),
    },
    {
      title: 'Fees',
      dataIndex: 'fees',
      key: 'fees',
      render: (text, record) => (
        <p>{Number(record?.transactionFee || 0)?.toLocaleString()}</p>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, render) => (
        <Tag
          color={
            {
              'SUCCESSFUL': 'green',
              'PENDING': 'yellow',
              'FAILED': 'red',
            }[render?.status] || 'blue'
          }
          key={render.enabled}>
          {render.status}
        </Tag>
      ),
    },
    {
      title: 'Date & Time',
      dataIndex: 'time',
      key: 'time',
      render: (text, record) => (
        <p className={styles.reference}>
          {moment(record?.createdAt).format('M/D/YY hh:mm a')}
        </p>
      ),
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.nav}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>Go to Wallet Overview</h1>
      </div>
      <section className={styles.contents}>
        <div className={styles.header}>
          <h1>
            {provider || displayType ? `${provider || displayType} -` : ''}{' '}
            {currency} Transactions
          </h1>
          <div className={styles.filterSection}>
            <Dropdown
              trigger="click"
              menu={{
                items: [
                  {
                    key: 1,
                    label: 'Manual Deposit',
                    onClick: () =>
                      setOpenTxModal({isOpen: true, type: 'DEPOSIT'}),
                  },
                  {
                    key: 2,
                    label: 'Manual Withdrawal',
                    onClick: () =>
                      setOpenTxModal({isOpen: true, type: 'WITHDRAWAL'}),
                  },
                ],
              }}>
              <div>
                <Button
                  className={styles.txButton}
                  type="secondary"
                  label=" Create Manual Transaction"
                />
              </div>
            </Dropdown>
            <Button
              className={styles.txButton}
              onClick={() => setOpenEmailModal({isOpen: true})}
              label="Add Email(s) for Failure/Pending TX"
            />
            <div
              onClick={() => setOpenFilterModal(!openFilterModal)}
              className={styles.filter}>
              <h2>Filter</h2>
              <img src={filterIcon} alt="" />
            </div>
            {openFilterModal && (
              <div className={styles.filterItems}>
                <h3>Filter</h3>
                <Form
                  form={form}
                  onFinish={handleFilter}
                  className={styles.form}
                  layout="vertical">
                  <Form.Item name="actionType" label="Transaction Type">
                    <Select
                      style={{width: 310}}
                      value={actionType}
                      allowClear
                      onChange={e => setActionType(e)}>
                      <Select.Option key="ALL">All</Select.Option>
                      <Select.Option key="DEPOSIT">Deposit</Select.Option>
                      <Select.Option key="WITHDRAWAL">Withdrawal</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="search"
                    style={{width: 310}}
                    label={
                      <div>
                        <Tooltip
                          trigger="hover"
                          title="Search by the following ID type: Transaction, Reference, Provider">
                          Search by ID
                        </Tooltip>
                      </div>
                    }>
                    <Input
                      value={searchValue}
                      onChange={e => setSearchValue(e.target.value)}
                      placeholder="Search by ID"
                    />
                  </Form.Item>
                  <Form.Item name="status" label="Status">
                    <Select
                      style={{width: 310}}
                      value={activeStatus}
                      allowClear
                      onChange={e => setActiveStatus(e)}>
                      <Select.Option key="SUCCESSFUL">Success</Select.Option>
                      <Select.Option key="PENDING">Pending</Select.Option>
                      <Select.Option key="FAILED">Failed</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="startDate" label="Start Date">
                    <DatePicker
                      value={startDate}
                      format={'YYYY-MM-DD'}
                      allowClear
                      onChange={e =>
                        setStartDate(moment(e).format('YYYY-MM-DD'))
                      }
                      style={{width: 150}}
                    />
                  </Form.Item>
                  <Form.Item name="endDate" label="End Date">
                    <DatePicker
                      value={endDate}
                      allowClear
                      onChange={e => setEndDate(moment(e).format('YYYY-MM-DD'))}
                      style={{width: 150}}
                    />
                  </Form.Item>
                  <SubmitButton label="Save Changes" />
                </Form>
              </div>
            )}
          </div>
        </div>
        <div className={styles['nav-buttons']}>
          <div className={styles['page-number']}>{currentPage}</div>
        </div>
        <div className={styles.tableContainer}>
          <Table
            className={styles.table}
            loading={loading}
            onRow={record => ({
              onClick: () => {
                setUpdateModal({isOpen: true, content: record});
              },
              className: styles.tableRow,
            })}
            columns={column}
            pagination={false}
            dataSource={data?.getAllTransactionsForAdmin?.transactions}
          />
          <Pagination
            showQuickJumper
            current={currentPage}
            showSizeChanger
            onChange={e => handlePageChange(e)}
            className="ant-table-pagination"
            total={totalPages}
            pageSize={limit}
            onShowSizeChange={(_, size) => {
              setLimit(size);
              setCurrentPage(1);
              handleFilter('', 1, size);
            }}
          />
        </div>
      </section>
      <DBModal
        isOpen={openBankModal}
        content={<WireTransferDetails data={bankDetails} />}
        handleClose={() => setOpenBankModal(false)}
      />
      <ManualTxModal
        isOpen={openTxModal?.isOpen}
        onClose={() => setOpenTxModal(false)}
        type={openTxModal?.type}
        refetch={refetch}
      />
      <EmailTriggerModal
        isOpen={openEmailModal.isOpen}
        onCloseModal={() => setOpenEmailModal({isOpen: false})}
      />
      <DBModal
        isOpen={updateModal.isOpen}
        handleClose={() => setUpdateModal({isOpen: false, content: null})}
        width={1000}
        content={
          <UpdateWalletTransaction
            currency={updateModal?.content?.currency}
            id={updateModal?.content?.id}
            operation={tabOperations?.update}
            refetch={() => handleFilter('', currentPage, limit)}
            closeModal={() => setUpdateModal({isOpen: false, content: null})}
            userId={updateModal?.content?.user?.id}
          />
        }
      />
    </div>
  );
};

export default WalletTransaction;
