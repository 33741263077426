import {
  Dropdown,
  Form,
  Input,
  Pagination,
  Select,
  Spin,
  Switch,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import styles from './userWalletSettings.module.scss';
import {useEffect, useState} from 'react';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {UPDATE_USER_WALLET_SETTINGS} from 'graphql/mutations/wallet';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {GET_USER_WALLET_DETAILS} from 'graphql/queries/users';
import DBModal from 'components/modal/modal';
import {Button} from '@dabafinance/react-components';
import moment from 'moment';
import filterIcon from 'icons/filter.svg';

import {numberWithCommas, renderTransactionId} from 'utils/helper';
import ManualTxModal from './ManualTxModal';
import {SelectOutlined} from '@ant-design/icons';
import WireTransferDetails from 'pages/wallet/transactions/transferDetails/WireTransferDetails';
import {GET_ALL_WALLET_TRANSACTIONS_FOR_ADMIN} from 'graphql/queries/wallet';
import {currencySymbols} from 'utils/mock';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import UpdateWalletTransaction from 'pages/wallet/transactions/UpdateWalletTransaction/UpdateWalletTransaction';

const UserWalletSettings = () => {
  const [flagWallet, setFlagWallet] = useState(false);
  const [openTxModal, setOpenTxModal] = useState(false);
  const [flagDetails, setFlagDetails] = useState({});
  const [blockWallet, setBlockWallet] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [note, setNote] = useState('');
  const [walletId, setWalletId] = useState(null);
  const [bankDetails, setBankDetails] = useState({});
  const [openBankModal, setOpenBankModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [wallets, setWallets] = useState([]);
  const [activeWallet, setActiveWallet] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [form] = Form.useForm();
  const [updateModal, setUpdateModal] = useState({
    isOpen: false,
    content: null,
  });

  const [copied, setCopied] = useState(false);

  const handleCopy = referenceId => {
    navigator.clipboard.writeText(referenceId).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 400);
    });
  };

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Users),
  );

  const {id} = useParams();
  const {data, loading} = useQuery(GET_USER_WALLET_DETAILS, {
    variables: {
      getUserByIdId: id,
      userId: id,
      transactionsUserId2: id,
    },
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
  });

  const [updateUserWalletSettings, {loading: loadingWalletBlock}] = useMutation(
    UPDATE_USER_WALLET_SETTINGS,
  );

  const [loadWalletTransactions, {loading: loadingTransactions, refetch}] =
    useLazyQuery(GET_ALL_WALLET_TRANSACTIONS_FOR_ADMIN, {
      variables: {
        input: {
          currency: activeWallet,
          userId: id,
          pagination: {
            limit: 10,
            page: 1,
          },
        },
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        setTransactions(data?.getAllTransactionsForAdmin?.transactions);
        setTotalPages(data?.getAllTransactionsForAdmin?.totalDocs);
      },
      onError: error => toast.error(error.message),
    });

  const handleFilter = (values, page, limit) => {
    const {status, type} = values;
    refetch({
      input: {
        currency: activeWallet,
        filters: {
          status,
          ...(searchValue && {search: searchValue}),
          ...(type !== 'ALL' && {type}),
        },
        userId: id,
        pagination: {
          page: page ?? 1,
          limit: limit,
        },
      },
    });
    if (!page) setCurrentPage(1);
    setFilterValues(values);
    setOpenFilterModal(false);
  };

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
    handleFilter(filterValues, page, limit);
  };

  useEffect(() => {
    setCurrentPage(1);
    form.resetFields();
    setFilterValues({});
    if (activeWallet) {
      loadWalletTransactions();
    }
  }, [activeWallet]);

  useEffect(() => {
    if (data?.getUserById?.user?.wallets) {
      setWallets(data?.getUserById?.user?.wallets);
      setActiveWallet(data?.getUserById?.user?.wallets[0]?.currency);
    }
  }, [data?.getUserById?.user]);

  const handleOpenModal = (event, id, details) => {
    if (event.target.isConnected) {
      setWalletId(id);
      setFlagDetails(details);
      setOpenModal(true);
    }
  };

  const handleOpenTxModal = (data, event) => {
    if (event.target.isConnected) {
      setOpenBankModal(true);
      setBankDetails(data);
    }
  };

  const column = [
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Available Amount',
      dataIndex: 'availableAmount',
      key: 'availableAmount',
      render: (text, render) => (
        <span>
          {numberWithCommas(
            Number(render?.balance?.availableBalance)?.toFixed(2),
          )}
        </span>
      ),
    },
    {
      title: 'Available For Investing',
      dataIndex: 'investing',
      key: 'investing',
      render: (text, render) => (
        <span>
          {numberWithCommas(
            Number(render?.balance?.availableForInvesting).toFixed(2),
          )}
        </span>
      ),
    },
    {
      title: 'Pending Deposit Balance',
      dataIndex: 'deposit',
      key: 'deposit',
      render: (text, render) => (
        <span>{render?.balance?.pendingDepositBalance}</span>
      ),
    },
    {
      title: 'Pending Withdrawal Balance',
      dataIndex: 'PendingWithdrawal',
      key: 'pendingWithdrawal',
      render: (text, render) => (
        <span>{render?.balance?.pendingWithdrawalBalance}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, render) => (
        <Tag
          color={
            {
              'ACTIVE': 'green',
              'LOCKED': 'red',
              'FROZEN': 'orange',
              'PENDING': 'yellow',
              'EMPTY': 'cyan',
            }[render?.status]
          }
          key={render?.status}>
          {render?.status}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Button
          onClick={e => handleOpenModal(e, record?.id, record.accountFlags)}
          label="Block Wallet"
          type="secondary"
        />
      ),
    },
  ];

  const transactionsColumn = [
    {
      title: 'Reference ID',
      dataIndex: 'referenceId',
      key: 'referenceId',
      render: (text, record) => (
        <Tooltip
          trigger="hover"
          title={copied ? 'Copied' : record?.referenceId}>
          <p
            onClick={e => {
              e.stopPropagation();
              handleCopy(record?.referenceId);
            }}
            className={styles.reference}>
            {renderTransactionId(record?.referenceId)}
          </p>
        </Tooltip>
      ),
    },
    {
      title: 'Provider ID',
      dataIndex: 'providerId',
      key: 'providerId',
      render: (text, record) => (
        <Tooltip
          trigger="hover"
          title={
            copied ? 'Copied' : record?.paymentGateway?.metadata?.transactionId
          }>
          <p
            onClick={e => {
              e.stopPropagation();
              handleCopy(record?.paymentGateway?.metadata?.transactionId);
            }}
            className={styles.reference}>
            {renderTransactionId(
              record?.paymentGateway?.metadata?.transactionId,
            )}
          </p>
        </Tooltip>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <p>{`${record?.user.firstName} ${record?.user?.lastName}`}</p>
      ),
    },

    {
      title: 'Direction',
      dataIndex: 'direction',
      key: 'direction',
      render: (text, record) => (
        <Tag
          color={
            {
              'DEPOSIT': 'black',
              'WITHDRAWAL': 'default',
              'STOCK_BUY': 'magenta',
              'STOCK_SELL': 'cyan',
              'DIVIDEND_PAYOUT': 'orange',
              'DIVIDEND_REVERSAL': 'red',
              'CURRENCY_SWAP': 'geekblue',
              'VENTURE_INVESTMENT': 'purple',
              'VENTURE_FUND_INVESTMENT': 'gold',
              'BOND_INVESTMENT': 'volcano',
              'REWARDS_PAYIN': 'lime',
            }[record?.type] || 'blue'
          }>
          {record?.type?.replace(/_/g, ' ')}
        </Tag>
      ),
    },
    {
      title: 'Provider',
      dataIndex: 'paymentProvider',
      key: 'paymentProvider',
      render: (text, record) => (
        <span>
          {record?.paymentProvider
            ?.toLowerCase()
            ?.replace(/\b\w/g, c => c.toUpperCase())}
        </span>
      ),
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (text, record) => (
        <div style={{width: 170}}>
          <span>
            {record?.paymentMetadata?.paymentMethod ? (
              <Tag
                color={
                  record?.paymentMetadata?.paymentMethod === 'MOBILE_MONEY'
                    ? 'cyan'
                    : 'geekblue'
                }>
                {record?.paymentMetadata?.paymentMethod
                  .replace(/_/g, ' ')
                  .toLowerCase()
                  .replace(/\b\w/g, c => c.toUpperCase())}
              </Tag>
            ) : (
              '-- -- --'
            )}
          </span>
          <SelectOutlined
            onClick={e => {
              e.stopPropagation();
              handleOpenTxModal(record?.paymentMetadata, e);
            }}
          />
        </div>
      ),
    },
    {
      title: 'Total Amount',
      dataIndex: 'amountNetOfFees',
      key: 'amountNetOfFees',
      render: (text, record) => (
        <span>
          {currencySymbols[record?.currency]?.symbol}
          {Number(
            record?.totalTransactionAmountIncludingFees || 0,
          )?.toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Amount (Net of Fees)',
      dataIndex: 'amountNetOfFees',
      key: 'amountNetOfFees',
      render: (text, record) => (
        <p>
          {Number(record?.transactionAmountNetOfFees || 0)?.toLocaleString()}
        </p>
      ),
    },
    {
      title: 'Fees',
      dataIndex: 'fees',
      key: 'fees',
      render: (text, record) => (
        <p>{Number(record?.transactionFee || 0)?.toLocaleString()}</p>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, render) => (
        <Tag
          color={
            {
              'SUCCESSFUL': 'green',
              'PENDING': 'yellow',
              'FAILED': 'red',
            }[render?.status] || 'blue'
          }
          key={render.enabled}>
          {render.status}
        </Tag>
      ),
    },
    {
      title: 'Date & Time',
      dataIndex: 'time',
      key: 'time',
      render: (text, record) => (
        <p className={styles.reference}>
          {moment(record?.createdAt).format('M/D/YY hh:mm a')}
        </p>
      ),
    },
  ];

  const handleBlockUser = () => {
    updateUserWalletSettings({
      variables: {
        input: {
          userId: id,
          walletId,
          status: blockWallet,
          accountFlags: {
            isFlagged: flagWallet,
            notes: note,
          },
        },
      },
      refetchQueries: [
        {
          query: GET_USER_WALLET_DETAILS,
          variables: {
            getUserByIdId: id,
            userId: id,
            transactionsUserId2: id,
          },
        },
      ],
    })
      .then(({data: {updateUserWalletSettings}}) => {
        if (updateUserWalletSettings.__typename === 'Error') {
          toast.error(updateUserWalletSettings.message);
        } else {
          setOpenModal(false);
          toast.success('Settings Updated Successfully.');
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>
          {data?.getUserById?.user?.firstName}{' '}
          {data?.getUserById?.user?.lastName} Wallet(s)
        </h1>
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                key: 1,
                label: 'Manual Deposit',
                onClick: () => setOpenTxModal({isOpen: true, type: 'DEPOSIT'}),
              },
              {
                key: 2,
                label: 'Manual Withdrawal',
                onClick: () =>
                  setOpenTxModal({isOpen: true, type: 'WITHDRAWAL'}),
              },
            ],
          }}>
          <div>
            <Button label="Create Manual Transaction" />
          </div>
        </Dropdown>
      </div>
      <div className={styles['details-toggler']}>
        {wallets.map(tab => (
          <span
            key={tab?.currency}
            className={
              activeWallet === tab?.currency ? styles['active-toggle'] : ''
            }
            onClick={() => setActiveWallet(tab?.currency)}>
            {tab?.currency}
          </span>
        ))}
      </div>
      {activeWallet ? (
        <>
          <h2>{currencySymbols[activeWallet]?.name} Wallet Details</h2>
          <Table
            dataSource={[wallets?.find(data => data.currency === activeWallet)]}
            columns={column}
            pagination={false}
          />
          <div className={styles.tableContainer}>
            <div className={styles.filterSection}>
              <h2>Transactions</h2>
              <div
                onClick={() => setOpenFilterModal(!openFilterModal)}
                className={styles.filter}>
                <h2>Filter</h2>
                <img src={filterIcon} alt="" />
              </div>
              <div>
                {openFilterModal && (
                  <div className={styles.filterItems}>
                    <h2>Filter Transactions</h2>
                    <Form
                      form={form}
                      onFinish={handleFilter}
                      className={styles.form}
                      layout="vertical">
                      <Form.Item
                        name="search"
                        style={{width: 310}}
                        label={
                          <div>
                            <Tooltip
                              trigger="hover"
                              title="Search by the following ID type: Transaction, Reference, Provider, Order, Payout">
                              Search by ID
                            </Tooltip>
                          </div>
                        }>
                        <Input
                          value={searchValue}
                          onChange={e => setSearchValue(e.target.value)}
                          placeholder="Search by ID"
                        />
                      </Form.Item>
                      <Form.Item name="type" label="Transaction Type">
                        <Select style={{width: 310}} allowClear>
                          <Select.Option key="ALL">All</Select.Option>
                          <Select.Option key="DEPOSIT">Deposit</Select.Option>
                          <Select.Option key="WITHDRAWAL">
                            Withdrawal
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item name="status" label="Status">
                        <Select style={{width: 310}} allowClear>
                          <Select.Option key="SUCCESSFUL">
                            Successful
                          </Select.Option>
                          <Select.Option key="PENDING">Pending</Select.Option>
                          <Select.Option key="FAILED">Failed</Select.Option>
                        </Select>
                      </Form.Item>
                      <SubmitButton label="Save Changes" />
                    </Form>
                  </div>
                )}
              </div>
            </div>
            <Table
              className={styles.table}
              dataSource={transactions}
              loading={loadingTransactions}
              onRow={record => ({
                onClick: () => setUpdateModal({isOpen: true, content: record}),
                className: styles.tableRow,
              })}
              columns={transactionsColumn}
              pagination={false}
            />
          </div>
          <Pagination
            showQuickJumper
            current={currentPage}
            showSizeChanger
            onChange={e => handlePageChange(e)}
            className="ant-table-pagination"
            total={totalPages}
            pageSize={limit}
            onShowSizeChange={(_, size) => {
              setLimit(size);
              handleFilter(filterValues, currentPage, size);
            }}
          />
        </>
      ) : (
        <div>
          <h2>No Wallet Found</h2>
        </div>
      )}
      <DBModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        content={
          <Form onFinish={handleBlockUser} layout="vertical">
            <h1 className={styles.formHeader}>User Wallet Settings</h1>
            <Form.Item
              name="flagWallet"
              label="Do You want to FLAG this User's Wallet?">
              <Switch
                onChange={e => setFlagWallet(e)}
                defaultChecked={flagDetails?.isFlagged}
              />
            </Form.Item>
            <Form.Item
              initialValue={flagDetails?.notes}
              name="blockWallet"
              label="Wallet Status">
              <Select value={blockWallet} onChange={e => setBlockWallet(e)}>
                <Select.Option key="ACTIVE">Active</Select.Option>
                <Select.Option key="PENDING">Pending</Select.Option>
                <Select.Option key="LOCKED">Locked</Select.Option>
                <Select.Option key="EMPTY">Empty</Select.Option>
                <Select.Option key="FROZEN">Frozen</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              initialValue={flagDetails?.notes}
              name="note"
              label="Note">
              <Input.TextArea
                style={{width: 400}}
                rows={4}
                defaultValue={flagDetails?.notes}
                value={note}
                placeholder="What are your reasons for blocking this user wallet"
                onChange={e => setNote(e.target.value)}
              />
            </Form.Item>
            <SubmitButton
              label="Update User Wallet"
              disabled={loadingWalletBlock}
            />
          </Form>
        }
      />
      <ManualTxModal
        isOpen={openTxModal.isOpen}
        onClose={() => setOpenTxModal(false)}
        type={openTxModal.type}
        userId={id}
        refetch={refetch}
      />
      <DBModal
        isOpen={openBankModal}
        content={<WireTransferDetails data={bankDetails} />}
        handleClose={() => setOpenBankModal(false)}
      />
      <DBModal
        isOpen={updateModal.isOpen}
        handleClose={() => setUpdateModal({isOpen: false})}
        width={1000}
        content={
          <UpdateWalletTransaction
            currency={updateModal?.content?.currency}
            id={updateModal?.content?.id}
            closeModal={() => setUpdateModal({isOpen: false, content: null})}
            operation={
              tabOperations?.update &&
              ['WITHDRAWAL', 'DEPOSIT'].includes(updateModal?.content?.type)
            }
            refetch={() => handleFilter(filterValues, currentPage, limit)}
            userId={updateModal?.content?.user?.id}
          />
        }
      />
    </div>
  );
};

export default UserWalletSettings;
