import {useNavigate} from 'react-router-dom';
import styles from './updateWalletTransaction.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {
  GET_ALL_WALLET_TRANSACTIONS_FOR_ADMIN,
  GET_WALLET_TRANSACTION_BY_ID,
} from 'graphql/queries/wallet';
import {Form, Input, Select, Spin} from 'antd';
import {useEffect, useState} from 'react';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {UPDATE_USER_WALLET_TRANSACTION} from 'graphql/mutations/wallet';
import {toast} from 'react-toastify';

const UpdateWalletTransaction = ({
  currency,
  closeModal,
  operation,
  id,
  refetch,
  userId,
}) => {
  const [transactionStatus, setTransactionStatus] = useState('');
  const navigate = useNavigate();

  const {loading, data} = useQuery(GET_WALLET_TRANSACTION_BY_ID, {
    variables: {
      transactionId: id,
      currency,
      userId,
    },
  });

  const [updateUserWalletTransaction, {loading: loadingUpdate}] = useMutation(
    UPDATE_USER_WALLET_TRANSACTION,
  );

  useEffect(() => {
    setTransactionStatus(data?.adminGetWalletTransactionById?.status);
  }, [data?.adminGetWalletTransactionById?.status]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const handleUpdateTransaction = () => {
    updateUserWalletTransaction({
      variables: {
        input: {
          currency,
          transactionId: id,
          status: transactionStatus,
          userId: data?.adminGetWalletTransactionById?.user?.id,
        },
      },
      refetchQueries: [
        {
          query: GET_ALL_WALLET_TRANSACTIONS_FOR_ADMIN,
          variables: {
            input: {
              currency,
            },
          },
        },
      ],
    })
      .then(({data: {updateUserWalletTransaction}}) => {
        if (updateUserWalletTransaction.__typename === 'Error') {
          toast.error(updateUserWalletTransaction.message);
        } else {
          toast.success('Settings Updated Successfully.');
          refetch();
          closeModal();
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <div className={styles.root}>
      <div className={styles.nav}>
        <h1>Update User Transaction</h1>
      </div>
      <div className={styles.profile}>
        <img src={data?.adminGetWalletTransactionById?.user?.imageUrl} alt="" />
        <div
          onClick={() => navigate(`/dashboard/user/${userId}/wallet-settings`)}>
          <h2>
            {data?.adminGetWalletTransactionById?.user?.firstName}{' '}
            {data?.adminGetWalletTransactionById?.user?.lastName}
          </h2>
          <span>@{data?.adminGetWalletTransactionById?.user?.username}</span>
        </div>
      </div>
      <section className={styles.content}>
        <Form onFinish={handleUpdateTransaction} layout="vertical">
          <div className={styles.form}>
            <Form.Item label="Currency">
              <Input disabled value={currency} style={{width: 270}} />
            </Form.Item>
            <Form.Item label="Type">
              <Input
                disabled
                value={data?.adminGetWalletTransactionById?.type}
                style={{width: 270}}
              />
            </Form.Item>
            <Form.Item label="Reference ID">
              <Input
                disabled
                value={data?.adminGetWalletTransactionById?.referenceId}
                className={styles.largeWidth}
              />
            </Form.Item>
            <Form.Item label="Provider ID">
              <Input
                disabled
                value={
                  data?.adminGetWalletTransactionById?.paymentGateway?.metadata
                    ?.transactionId
                }
                className={styles.largeWidth}
              />
            </Form.Item>
            <Form.Item label="Transaction Fee">
              <Input
                disabled
                value={data?.adminGetWalletTransactionById?.transactionFee}
                className={styles.largeWidth}
              />
            </Form.Item>
            <Form.Item label="Total Transaction Amount (Including Fees)">
              <Input
                disabled
                value={
                  data?.adminGetWalletTransactionById
                    ?.totalTransactionAmountIncludingFees
                }
                className={styles.largeWidth}
              />
            </Form.Item>
            <Form.Item label="Transaction Amount (Net of Fees)">
              <Input
                disabled
                value={
                  data?.adminGetWalletTransactionById
                    ?.transactionAmountNetOfFees
                }
                className={styles.largeWidth}
              />
            </Form.Item>
          </div>

          <div className={styles.updateStatus}>
            <h1>Update Transaction Status</h1>
            <Form.Item
              name="status"
              label="Transaction Status"
              style={{width: 270}}
              initialValue={data?.adminGetWalletTransactionById?.status}
              rules={[
                {
                  required: true,
                  message: 'Please select a status before updating',
                },
              ]}>
              <Select
                value={transactionStatus}
                onChange={e => setTransactionStatus(e)}
                defaultValue={data?.adminGetWalletTransactionById?.status}
                placeholder="Select a Status">
                <Select.Option key="SUCCESSFUL">Successful</Select.Option>
                <Select.Option key="FAILED">Failed</Select.Option>
              </Select>
            </Form.Item>
            <SubmitButton
              disabled={loadingUpdate || !operation}
              label="Update Transaction"
            />
          </div>
        </Form>
      </section>
    </div>
  );
};

export default UpdateWalletTransaction;
