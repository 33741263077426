import DBModal from 'components/modal/modal';
import {walletCurrencies} from 'utils/mock';
import styles from './payouts.module.scss';
import {DatePicker, Form, Input, Select, Spin} from 'antd';
import {
  BULK_UPLOAD_PAYOUT,
  EXPORT_CSV_USERS_PAYOUT,
  GET_INVESTED_USER_BY_DATA_RANGE,
  PROCESS_SINGLE_USER_PAYOUT,
} from 'graphql/queries/dividends';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import moment from 'moment';
import {handleUpdateDividendId} from 'redux/store/app';
import {useDispatch} from 'react-redux';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {UploadVideoToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import {
  handleCSVPayoutUpload,
  handleDefaultPayout,
  handleProcessCSVPayout,
  handleSingleUser,
} from './handlePayoutFunctions';
import {toast} from 'react-toastify';
import {GET_ASSET_CLASS_INSTRUMENTS} from 'graphql/queries/assetManagement';
import _ from 'lodash';

export const ProcessDividendPayout = ({
  openPayoutModal,
  onClose,
  activeTab,
  onFilter,
  resetPage,
  handleFilterValues,
}) => {
  const [loadingCSVUpload, setLoadingCSVUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState('');
  const [csvUrl, setCSVUrl] = useState('');
  const [csvName, setCsvName] = useState('');
  const [form] = Form.useForm();
  const category = Form.useWatch('investmentCategory', form);
  const type = Form.useWatch('type', form);
  const dispatch = useDispatch();
  const [user, setUser] = useState([]);
  const [instruments, setInstruments] = useState([]);

  // RESOLVERS
  const {data: getAllCompanies, loading: loadingCompanies} =
    useQuery(GET_ALL_STARTUPS);
  const [getInvestedUsersByDateRange, {loading: loadingInvestedUsers}] =
    useLazyQuery(GET_INVESTED_USER_BY_DATA_RANGE, {
      errorPolicy: 'all',
    });
  const [bulkUploadDividendPayout, {loading: loadingBulkPayout}] = useMutation(
    BULK_UPLOAD_PAYOUT,
    {
      errorPolicy: 'all',
      onError: error => toast.error('Something went wrong', error.message),
    },
  );
  const [
    fetchInstruments,
    {refetch: refetchInstrument, loading: loadingInstruments},
  ] = useLazyQuery(GET_ASSET_CLASS_INSTRUMENTS);

  const [addDividendPayout, {loading: loadingSinglePayout}] = useMutation(
    PROCESS_SINGLE_USER_PAYOUT,
    {
      errorPolicy: 'all',
      onError: error => toast.error('Something went wrong', error.message),
    },
  );

  const [generateAndExportInvestedUsers, {loading: loadingExportCSV}] =
    useLazyQuery(EXPORT_CSV_USERS_PAYOUT, {
      errorPolicy: 'all',
      onError: error => toast.error('Something went wrong: ', error.message),
    });

  useEffect(() => {
    if (category === 'BOND') {
      fetchInstruments({
        variables: {
          input: {
            explore: false,
            instrumentKey: 'BOND',
            assetClassKey: 'FIXED_INCOME',
          },
        },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: error => toast.error(error.message),
        onCompleted: data =>
          setInstruments(data?.getFinancialInstruments?.data || []),
      });
    }
  }, [category]);

  const handlePayout = values => {
    const {
      startDate,
      endDate,
      company,
      investmentCategory,
      instrumentId,
      type,
    } = values;
    if (activeTab !== 'PAYOUT') {
      let bondId, issuerId;
      let isBondType = investmentCategory === 'BOND';

      if (isBondType) {
        bondId = instrumentId?.split(';')[0];
        issuerId = instrumentId?.split(';')[1];
      }
      const validFields = {
        ...((company || issuerId) && {
          companyId: isBondType ? issuerId : company.split(';')[1],
          ...(bondId && {instrumentId: bondId}),
        }),
        investmentCategory,
        ...(startDate && {
          startDate: moment(startDate).format(),
        }),
        ...(endDate && {
          endDate: moment(endDate).format(),
        }),
      };
      console.log(validFields);
      handleFilterValues({
        ...validFields,
      });
      onFilter(
        {
          ...validFields,
        },
        1,
      );
      resetPage();
      return onClose();
    }

    if (type === 'DEFAULT' && openPayoutModal?.type !== 'CSV') {
      handleDefaultPayout(
        values,
        getInvestedUsersByDateRange,
        dispatch,
        handleUpdateDividendId,
        onClose,
        form,
      );
    } else if (type === 'CSV_UPLOAD') {
      handleCSVPayoutUpload(
        values,
        csvUrl,
        setCSVUrl,
        bulkUploadDividendPayout,
        dispatch,
        handleUpdateDividendId,
        onClose,
        form,
      );
    } else if (type === 'USER') {
      handleSingleUser(
        values,
        addDividendPayout,
        dispatch,
        onClose,
        handleUpdateDividendId,
        user,
        form,
      );
    } else {
      handleProcessCSVPayout(
        values,
        generateAndExportInvestedUsers,
        onClose,
        form,
      );
    }
  };

  const handleCSVUPload = async e => {
    setUploadProgress(0);
    const selectedFile = e.target.files[0];
    setCsvName(selectedFile.name);
    setLoadingCSVUpload(true);
    const response = await UploadVideoToBucket(
      selectedFile,
      'campaign-bucket-images',
      setUploadProgress,
    );
    setCSVUrl(response);
    setLoadingCSVUpload(false);
  };

  const handleSearch = _.debounce(async value => {
    try {
      const {data} = await refetchInstrument({
        input: {
          explore: false,
          instrumentKey: 'BOND',
          assetClassKey: 'FIXED_INCOME',
          search: value,
          page: 1,
          limit: 100,
        },
      });
      setInstruments(data.getFinancialInstruments?.data || []);
    } catch (error) {
      console.error('Error fetching instruments:', error);
    }
  }, 1200);

  return (
    <DBModal
      isOpen={openPayoutModal}
      handleClose={onClose}
      content={
        <div className={styles.modal}>
          <h1>
            {activeTab === 'PAYOUT'
              ? openPayoutModal?.type === 'CSV'
                ? 'Process & Export Dividends Payout'
                : 'Finalize Dividend Payout'
              : 'Search Paid Dividends'}
          </h1>
          <Form form={form} onFinish={handlePayout} layout="vertical">
            {activeTab === 'PAYOUT' && (
              <Form.Item
                initialValue="DEFAULT"
                name="type"
                label="Type of Payout">
                <Select defaultValue="DEFAULT">
                  <Select.Option key="DEFAULT">Default</Select.Option>
                  {openPayoutModal?.type !== 'CSV' && (
                    <>
                      <Select.Option key="CSV_UPLOAD">CSV Upload</Select.Option>
                      <Select.Option key="USER">Single User</Select.Option>
                    </>
                  )}
                </Select>
              </Form.Item>
            )}
            {type === 'CSV_UPLOAD' && activeTab === 'PAYOUT' && (
              <div className={styles.uploadSec}>
                <label className={styles.upload} htmlFor="file-upload">
                  {loadingCSVUpload ? (
                    <div className={styles.uploading}>
                      <Spin />
                      <h3>Uploading, Please wait...</h3>
                      <span>{uploadProgress}%</span>
                    </div>
                  ) : csvName && csvUrl ? (
                    <div>{csvName}</div>
                  ) : (
                    <h2>Upload CSV File</h2>
                  )}
                  <input
                    type="file"
                    id="file-upload"
                    accept=".csv"
                    className={styles['input-file']}
                    required
                    onChange={handleCSVUPload}
                  />
                </label>
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://daba-wp-bucket.s3.us-east-1.amazonaws.com/cdn/dividend-sample.csv">
                    Sample CSV
                  </a>
                </div>
              </div>
            )}
            {type === 'USER' && (
              <Form.Item name="user" label="Select User">
                <UserSelectField
                  users={user}
                  setUsers={setUser}
                  mode="single"
                />
              </Form.Item>
            )}
            <Form.Item
              rules={[
                {
                  required: activeTab === 'PAYOUT',
                  message: 'This is a Required Field',
                },
              ]}
              className={styles.field}
              name="investmentCategory"
              label="Investment Category">
              <Select allowClear>
                <Select.Option key={'STOCK'}>Stock</Select.Option>
                <Select.Option key={'VENTURE'}>Venture</Select.Option>
                <Select.Option key={'IPO'}>IPO</Select.Option>
                <Select.Option key={'BOND'}>BOND</Select.Option>
              </Select>
            </Form.Item>
            {category === 'BOND' ? (
              <Form.Item
                rules={[
                  {
                    required: activeTab === 'PAYOUT',
                    message: 'This is a Required Field',
                  },
                ]}
                name="instrumentId"
                label="Select Bond">
                <Select
                  showSearch
                  onSearch={handleSearch}
                  filterOption={false}
                  placeholder="Search and Select Instrument"
                  loading={loadingInstruments}
                  allowClear>
                  {instruments.map(instrument => (
                    <Select.Option
                      key={`${instrument?.id};${instrument?.issuer?.id}`}>
                      {instrument?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                className={styles.field}
                name="company"
                rules={[
                  {
                    required: activeTab === 'PAYOUT',
                    message: 'This is a Required Field',
                  },
                ]}
                label="Company">
                <Select allowClear showSearch loading={loadingCompanies}>
                  {getAllCompanies?.getAllCompanies?.map(company => (
                    <Select.Option key={`${company?.name};${company?.id}`}>
                      <div className={styles['all-data']}>
                        <img src={company?.logoImgURL} alt="" />
                        <span>{company?.name}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {activeTab === 'PAYOUT' && (
              <>
                <Form.Item
                  rules={[
                    {required: true, message: 'This is a Required Field'},
                  ]}
                  className={styles.field}
                  name="assetCurrency"
                  label="Currency">
                  <Select>
                    {walletCurrencies.map(data => (
                      <Select.Option key={data.key}>{data.key}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {type !== 'CSV_UPLOAD' && openPayoutModal?.type !== 'CSV' && (
                  <>
                    <Form.Item
                      className={styles.field}
                      name="principalPerShare"
                      label="Principle Per Share">
                      <Input type="number" />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {required: true, message: 'This is a Required Field'},
                      ]}
                      className={styles.field}
                      name="amountPerShare"
                      label="Amount Per Share">
                      <Input type="number" />
                    </Form.Item>
                  </>
                )}
                {type === 'USER' && (
                  <Form.Item
                    rules={[
                      {required: true, message: 'This is a Required Field'},
                    ]}
                    className={styles.field}
                    name="units"
                    label="Units">
                    <Input type="number" />
                  </Form.Item>
                )}
              </>
            )}
            <div className={styles.dates}>
              <Form.Item
                rules={[
                  {
                    required: activeTab === 'PAYOUT',
                    message: 'This is a Required Field',
                  },
                ]}
                className={styles.field}
                name="startDate"
                label="Start Date">
                <DatePicker
                  allowClear
                  format={'YYYY-MM-DD HH:mm:ss'}
                  showTime={{
                    defaultValue: moment('00:00', 'HH:mm:ss'),
                  }}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: activeTab === 'PAYOUT',
                    message: 'This is a Required Field',
                  },
                ]}
                className={styles.field}
                name="endDate"
                label="End Date">
                <DatePicker
                  allowClear
                  format={'YYYY-MM-DD HH:mm:ss'}
                  showTime={{
                    defaultValue: moment('00:00', 'HH:mm:ss'),
                  }}
                />
              </Form.Item>
            </div>
            <SubmitButton
              disabled={
                loadingInvestedUsers ||
                loadingBulkPayout ||
                loadingSinglePayout ||
                loadingExportCSV
              }
              label={
                activeTab === 'PAYOUT'
                  ? openPayoutModal?.type === 'CSV'
                    ? 'Export Payouts'
                    : 'Finalize Payouts'
                  : 'SEARCH'
              }
            />
          </Form>
        </div>
      }
    />
  );
};
