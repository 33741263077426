import {useQuery} from '@apollo/client';
import styles from './adminNps.module.scss';
import {GET_ALL_IN_APP_REVIEWS} from 'graphql/queries/nps';
import moment from 'moment';
import {Button} from '@dabafinance/react-components';
import {Dropdown, Form, Pagination, Select, Table} from 'antd';
import gamer from 'images/gamer.png';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useState} from 'react';
import DBModal from 'components/modal/modal';
import {CheckOutlined, CopyOutlined} from '@ant-design/icons';
import PercentageCard from 'pages/adminUser/card/PercentageCard';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {truncateTitle} from 'utils/helper';

export const CustomerReviews = () => {
  const [statistics, setStatistics] = useState({});
  const [filterData, setFilterData] = useState({reason: ''});
  const [modal, setModal] = useState({open: false, data: null});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [copyStatus, setCopyStatus] = useState({phone: false, email: false});
  const navigate = useNavigate();

  const {data, loading, refetch} = useQuery(GET_ALL_IN_APP_REVIEWS, {
    variables: {
      input: {
        pagination: {
          page: 1,
          limit: 10,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    onError: error => toast.error(error.message),
    onCompleted: data => setStatistics(data?.getInAppReviews?.data?.statistics),
  });

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
    handleFilter(filterData, limit, page);
  };

  const handleCopy = type => {
    const text =
      type === 'phone'
        ? modal?.data?.user?.phoneNumber
        : modal?.data?.user?.email;
    navigator.clipboard.writeText(text);
    setCopyStatus(prev => ({...prev, [type]: true}));
    setTimeout(() => {
      setCopyStatus(prev => ({...prev, [type]: false}));
    }, 1000);
  };

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div
            onClick={() => navigate(`/dashboard/user/${record?.user?.id}`)}
            className={styles['record-name']}>
            {record?.user?.imageUrl ? (
              <img src={record?.user?.imageUrl} alt="" />
            ) : (
              <img src={gamer} alt="" />
            )}
            <h4>
              {record?.user?.firstName} {record?.user?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    //   key: 'type',
    //   render: (text, record) => <Tag color>{record?.feedback}</Tag>,
    // },
    {
      title: 'Feedback',
      dataIndex: 'feedback',
      key: 'feedback',
      render: (text, record) => (
        <div>
          <span>{truncateTitle(record?.message ?? 'N/A')}</span>
        </div>
      ),
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'creationdate',
      render: (text, record) => (
        <span>{moment(record?.createdAt).format('DD MMM YYYY hh:mm A')}</span>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div>
          <Button
            onClick={() => setModal({open: true, data: record})}
            type="secondary"
            label="View Details"
            className={styles['deactivate-btn']}
          />
        </div>
      ),
    },
  ];

  const handleFilter = (values, limit, page) => {
    setLimit(limit);
    setCurrentPage(page);
    setFilterData(values);

    refetch({
      input: {
        pagination: {
          limit,
          page,
        },
        reason: values?.reason,
      },
    });
  };

  return (
    <div className={styles['container-wrapper']}>
      <div className={styles.statisticsContainer}>
        <PercentageCard
          label="Total Review Count"
          amount={statistics?.totalReviewsCount || 0}
          active
        />
        {!!statistics?.reasonStatistics &&
          statistics?.reasonStatistics?.map(stat => (
            <PercentageCard
              key={stat?.reason}
              label={stat?.text}
              amount={stat?.count}
              active
            />
          ))}
      </div>
      <div className={styles['startup-table']}>
        <div className={styles.sortTable}>
          <h1>Customers Feedback</h1>
          <Form
            className={styles.form}
            onFinish={values => handleFilter(values, limit, 1)}
            layout="horizontal">
            <Form.Item className={styles.formItem} name="reason" label="Reason">
              <Select placeholder="Filter by a Reason" allowClear>
                {reasons.map(reason => (
                  <Select.Option key={reason.value} value={reason.value}>
                    {reason.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <SubmitButton smallWidth secondary label="Filter" />
          </Form>
        </div>
        <div className={styles['table-height']}>
          <Table
            columns={columns}
            dataSource={data?.getInAppReviews?.data?.reviews}
            loading={loading}
            pagination={false}
          />
        </div>
        <Pagination
          className="ant-table-pagination"
          current={currentPage}
          onChange={handlePageChange}
          showSizeChanger
          onShowSizeChange={(_, size) => {
            setCurrentPage(1);
            handleFilter(filterData, size, 1);
          }}
          total={data?.getInAppReviews?.pagination?.total}
        />
      </div>
      <DBModal
        isOpen={modal.open}
        handleClose={() => setModal({open: false, data: null})}
        width={800}
        content={
          <div className={styles.reviewModal}>
            <div className={styles.reviewHeader}>
              <h1>
                {modal?.data?.user?.firstName} {modal?.data?.user?.lastName}
              </h1>
              <div className={styles.icon}>
                {thumbsDown}
                <span>Negative Feedback</span>
              </div>
            </div>
            <h2>Feedback</h2>
            <p>{modal?.data?.message}</p>
            <Dropdown
              trigger="click"
              className={styles.dropdownBtn}
              menu={{
                items: [
                  {
                    label: (
                      <div
                        onClick={() => handleCopy('phone')}
                        style={{cursor: 'pointer'}}>
                        Copy Phone Number{' '}
                        {copyStatus.phone ? (
                          <CheckOutlined />
                        ) : (
                          <CopyOutlined />
                        )}
                      </div>
                    ),
                    key: 1,
                  },
                  {
                    label: (
                      <div
                        onClick={() => handleCopy('email')}
                        style={{cursor: 'pointer'}}>
                        Copy Email{' '}
                        {copyStatus.email ? (
                          <CheckOutlined />
                        ) : (
                          <CopyOutlined />
                        )}
                      </div>
                    ),
                    key: 2,
                  },
                ],
              }}>
              <div>
                <Button
                  className={styles.dropdownButton}
                  label="Contact Customer"
                />
              </div>
            </Dropdown>
          </div>
        }
      />
    </div>
  );
};

const thumbsDown = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1152 8.58594L12.4589 3.33594C12.4189 3.01869 12.2645 2.72694 12.0247 2.51548C11.7848 2.30402 11.476 2.18739 11.1562 2.1875H1.75C1.51794 2.1875 1.29538 2.27969 1.13128 2.44378C0.967187 2.60788 0.875 2.83044 0.875 3.0625V7.875C0.875 8.10706 0.967187 8.32962 1.13128 8.49372C1.29538 8.65781 1.51794 8.75 1.75 8.75H4.10484L6.17094 12.8833C6.20731 12.956 6.26321 13.0171 6.33237 13.0598C6.40153 13.1025 6.48122 13.1251 6.5625 13.125C7.14266 13.125 7.69906 12.8945 8.1093 12.4843C8.51953 12.0741 8.75 11.5177 8.75 10.9375V10.0625H11.8125C11.9987 10.0626 12.1829 10.023 12.3527 9.9464C12.5224 9.86981 12.674 9.75797 12.7972 9.61831C12.9204 9.47864 13.0125 9.31435 13.0673 9.13635C13.1221 8.95835 13.1384 8.77073 13.1152 8.58594ZM3.9375 7.875H1.75V3.0625H3.9375V7.875Z"
      fill="#D62323"
    />
  </svg>
);

const reasons = [
  {name: 'User Interface', value: 'USER_INTERFACE'},
  {name: 'Lack of Features', value: 'LACK_OF_FEATURES'},
  {name: 'Performance', value: 'PERFORMANCE'},
  {name: 'Technical Issues', value: 'TECHNICAL_ISSUES'},
  {name: 'High Fees', value: 'HIGH_FEES'},
  {name: 'Others', value: 'Others'},
];
